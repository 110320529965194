// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Dashboard components
import PieChart from "./components/PieChart";
import StatusChart from "./components/StatusChart";
import TurningNPA from "./components/TurningNPA/TurningNPA";
import NoActionTaken from "./components/NoAction/NoActionTaken";
import ActionsDueAccounts from "./components/ActionDue/ActionsDueAccounts";
import { useEffect, useState } from "react";
import axios from "axios";
import Loader from "components/Loader";
import BarChart from "./components/Bar Chart";
import { useNavigate } from "react-router-dom";
import OfflineAlertsPieChart from "./components/OfflineAlertPieChart";
import AlertsCount from "./components/AlertsCount";

function Dashboard() {
  const [loading, setLoading] = useState(true);
  const branch_code = sessionStorage.getItem("branch_code");
  const [data, setData] = useState();
  const url = process.env.REACT_APP_BACKEND_URL + `/dashboard/data?BranchCode=${branch_code}`;
  const getDashboardData = async () => {
    try {
      const res = await axios.get(`${url}`);
      if (res.status === 200) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getDashboardData();
  }, []);

  const navigate = useNavigate();
  const handleView = (number, category) => {
    console.log(category + "category");
    if (!category && category != 0) {
      return;
    }
    let visitUrl = null;
    if (category == "01") {
      visitUrl = `/standard/summary/dashboard/${number}`;
    } else if (category == "SMA0" || category == "SMA1" || category == "SMA2") {
      visitUrl = `/sma/summary/dashboard/${number}`;
    } else {
      visitUrl = `/npa/summary/dashboard/${number}`;
    }
    navigate(visitUrl);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Loader />
      ) : (
        <MDBox py={3}>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <PieChart data={data?.pieChart} handleView={handleView} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StatusChart
                  actionDueAccounts={data?.actionDueTable?.length}
                  noActionTakenAccounts={data?.noActionTable?.length}
                  npaTurningAccounts={data?.npaTurningTable?.length}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <OfflineAlertsPieChart
                  data={data?.offlineAlertsCount || []}
                  handleView={handleView}
                />
              </Grid> */}
              <Grid item xs={12}>
                <NoActionTaken
                  data={data?.noActionTable?.length ? data?.noActionTable : []}
                  handleView={handleView}
                />
              </Grid>
              <Grid item xs={12}>
                <ActionsDueAccounts
                  data={data?.actionDueTable?.length ? data?.actionDueTable : []}
                  handleView={handleView}
                />
              </Grid>
              <Grid item xs={12}>
                <TurningNPA
                  data={data?.npaTurningTable?.length ? data.npaTurningTable : []}
                  handleView={handleView}
                />
              </Grid>
              {/* <Grid item xs={12} md={12} lg={12}>
              <Projects />
            </Grid> */}
            </Grid>
          </MDBox>
        </MDBox>
      )}

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Dashboard;
