// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AlertsCount_container__sEwO5 {
    display: flex;
    flex-wrap: wrap;
    gap: 22px;
    justify-content: space-between;
    border-radius: 12px;
}

.AlertsCount_card__1cM41 {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    min-width: 250px;
    justify-content: flex-start;
    height: 100px;
    border: 1px solid #ddd;
    border-radius: 15px;
    background-color: #fff;
    transition: transform 0.3s, box-shadow 0.3s;
}

.AlertsCount_card__1cM41:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.AlertsCount_icon__bkTpl {
    margin-right: 15px;
    color: white;
    padding: 10px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
}


.AlertsCount_content__OYVvB {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.AlertsCount_heading__-z5BJ {
    margin: 0;
    font-size: 18px;
    color: #333;
    font-weight: 500;
}

.AlertsCount_number__fGk4o {
    margin: 0;
    font-size: 24px;
    color: #333;
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/layouts/dashboard/components/AlertsCount/AlertsCount.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,SAAS;IACT,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,SAAO;IACP,mBAAmB;IACnB,mBAAmB;IACnB,aAAa;IACb,gBAAgB;IAChB,2BAA2B;IAC3B,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,sBAAsB;IACtB,2CAA2C;AAC/C;;AAEA;IACI,2BAA2B;IAC3B,yCAAyC;AAC7C;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB;;;AAGA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,SAAS;IACT,eAAe;IACf,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,SAAS;IACT,eAAe;IACf,WAAW;IACX,iBAAiB;AACrB","sourcesContent":[".container {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 22px;\n    justify-content: space-between;\n    border-radius: 12px;\n}\n\n.card {\n    display: flex;\n    flex: 1;\n    flex-direction: row;\n    align-items: center;\n    padding: 15px;\n    min-width: 250px;\n    justify-content: flex-start;\n    height: 100px;\n    border: 1px solid #ddd;\n    border-radius: 15px;\n    background-color: #fff;\n    transition: transform 0.3s, box-shadow 0.3s;\n}\n\n.card:hover {\n    transform: translateY(-5px);\n    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);\n}\n\n.icon {\n    margin-right: 15px;\n    color: white;\n    padding: 10px;\n    height: 50px;\n    width: 50px;\n    border-radius: 50%;\n}\n\n\n.content {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n}\n\n.heading {\n    margin: 0;\n    font-size: 18px;\n    color: #333;\n    font-weight: 500;\n}\n\n.number {\n    margin: 0;\n    font-size: 24px;\n    color: #333;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AlertsCount_container__sEwO5`,
	"card": `AlertsCount_card__1cM41`,
	"icon": `AlertsCount_icon__bkTpl`,
	"content": `AlertsCount_content__OYVvB`,
	"heading": `AlertsCount_heading__-z5BJ`,
	"number": `AlertsCount_number__fGk4o`
};
export default ___CSS_LOADER_EXPORT___;
