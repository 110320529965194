// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Upload_container__-zQXG {
    display: flex;
    height: calc(100vh - 150px);
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/layouts/Upload/Upload.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;IAC3B,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".container {\n    display: flex;\n    height: calc(100vh - 150px);\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Upload_container__-zQXG`
};
export default ___CSS_LOADER_EXPORT___;
