import React from "react";
import { IconBell, IconMail, IconMessageCircle, IconAlertCircle } from "@tabler/icons-react";
import styles from "./AlertsCount.module.css";
import { Divider } from "@mui/material";

export default function AlertsCount() {
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <IconBell size={32} className={styles.icon} style={{ backgroundColor: "#FF6868" }} />
        <Divider flexItem orientation="vertical" />
        <div className={styles.content}>
          <h3 className={styles.heading}>Total Alerts</h3>
          <p className={styles.number}>100</p>
        </div>
      </div>
      <div className={styles.card}>
        <IconMail size={32} className={styles.icon} style={{ backgroundColor: "#81B29A" }} />
        <Divider flexItem orientation="vertical" />
        <div className={styles.content}>
          <h3 className={styles.heading}>Resolved Alerts</h3>
          <p className={styles.number}>10</p>
        </div>
      </div>
      <div className={styles.card}>
        <IconMessageCircle
          size={32}
          className={styles.icon}
          style={{ backgroundColor: "#5BBCFF" }}
        />
        <Divider flexItem orientation="vertical" />
        <div className={styles.content}>
          <h3 className={styles.heading}>Pending Alerts</h3>
          <p className={styles.number}>10</p>
        </div>
      </div>
      <div className={styles.card}>
        <IconAlertCircle size={32} className={styles.icon} style={{ backgroundColor: "#FFBB64" }} />
        <Divider flexItem orientation="vertical" />
        <div className={styles.content}>
          <h3 className={styles.heading}>Offline Alerts</h3>
          <p className={styles.number}>10</p>
        </div>
      </div>
    </div>
  );
}
