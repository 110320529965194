import React from "react";
import ReactECharts from "echarts-for-react";
import { Card } from "@mui/material";

const BarChart = () => {
  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow", // Makes the tooltip trigger on axis hover
      },
      formatter: function (params) {
        const value = params[0].value;
        return `Count: ${value}`;
      },
    },
    xAxis: {
      type: "category",
      data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: [
          { value: 120 },
          // { value: 120 },
          { value: 200 },
          // { value: 200 },
          { value: 150 },
          // { value: 150 },
          { value: 80 },
          // { value: 80 },
          { value: 70 },
          // { value: 70 },
          { value: 110 },
          // { value: 110 },
          { value: 130 },
        ],
        type: "bar",
        barWidth: "50%", // Decrease the width of the bars
        itemStyle: {
          emphasis: {
            barBorderRadius: [5, 5, 0, 0], // Adds a little rounding to the top corners of the bars on hover
          },
        },
      },
    ],
  };

  return (
    <Card className="NewCard">
      <ReactECharts option={option} style={{ height: "470px", width: "100%" }} />
    </Card>
  );
};

export default BarChart;
