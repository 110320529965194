/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ListIcon from "@mui/icons-material/List";

// Summmary
import STD_Summary from "layouts/STD_Accounts/Summary";
import SMA_Summary from "layouts/SMA_Accounts/Summary";
import NPA_Summary from "layouts/NPA_Accounts/Summary";
import AccDashboard from "layouts/STD_Accounts/AccDashboard";

// actions

import Notice from "layouts/Actions/Notice";
import Inspection from "layouts/Actions/Inspection";
import Interview from "layouts/Actions/Interview";
import {
  IconAlertHexagon,
  IconAlertOctagon,
  IconAlertTriangle,
  IconDashboard,
  IconExclamationCircle,
  IconFileInvoice,
  IconFileSearch,
  IconList,
  IconListDetails,
  IconListTree,
  IconNews,
  IconNotebook,
  IconNotes,
  IconWorldUpload,
  IconZoomScan,
} from "@tabler/icons-react";
import Alerts from "layouts/Alerts/Alerts";
import Alerts_Authority from "layouts/Alerts/Alerts_Authority";
import Inspections from "layouts/Inspection_List";
import LoanReviewAccounts from "layouts/Loan_review_accounts";
import Notices from "layouts/Notice_List";

const routes = [
  {
    type: "title",
    name: "Dashboard",
    key: "dashboard-title",
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <IconDashboard />,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "title",
    name: "Summary",
    key: "Summary",
  },
  {
    type: "collapse",
    name: "Standard-Accounts",
    icon: <IconNotes />,
    key: "standard/summary",
    route: "/standard/summary",
    component: <STD_Summary />,
    childrens: [
      {
        type: "collapse",
        name: `Account-Dashboard`,
        key: `standard/summary/dashboard/:id`,
        route: "/standard/summary/dashboard/:id",
        component: <AccDashboard />,
      },
      {
        type: "collapse",
        name: `Account-Inspection`,
        key: `standard/summary/inspection`,
        route: "/standard/summary/inspection",
        component: <Inspection />,
      },
      {
        type: "collapse",
        name: `Account-Interview`,
        key: `standard/summary/interview`,
        route: "/standard/summary/interview",
        component: <Interview />,
      },
      {
        type: "collapse",
        name: `Account-Notice`,
        key: `standard/summary/notice`,
        route: "/standard/summary/notice",
        component: <Notice />,
      },
    ],
  },
  {
    type: "collapse",
    name: "SMA-Accounts",
    icon: <IconNotebook />,
    key: "sma/summary",
    route: "/sma/summary",
    component: <SMA_Summary />,
    childrens: [
      {
        type: "collapse",
        name: `Account-Dashboard`,
        key: `sma/summary/dashboard/:id`,
        route: "/sma/summary/dashboard/:id",
        component: <AccDashboard />,
      },
    ],
  },
  {
    type: "collapse",
    name: "NPA-Accounts",
    icon: <IconNews />,
    key: "npa/summary",
    route: "/npa/summary",
    component: <NPA_Summary />,
    childrens: [
      {
        type: "collapse",
        name: `Account-Dashboard`,
        key: `npa/summary/dashboard/:id`,
        route: "/npa/summary/dashboard/:id",
        component: <AccDashboard />,
      },
    ],
  },
  {
    type: "title",
    name: "Master",
    key: "Master",
  },
  {
    type: "collapse",
    name: "Inspections",
    icon: <IconFileSearch />,
    key: "Inspections",
    route: "Inspections",
    component: <Inspections />,
  },
  {
    type: "collapse",
    name: "Notices",
    icon: <IconFileInvoice />,
    key: "notices",
    route: "notices",
    // component: <Notices />,
  },
  {
    type: "collapse",
    name: "Loan Reviews",
    icon: <IconZoomScan />,
    key: "loan-review",
    route: "loan-review",
    component: <LoanReviewAccounts />,
  },
  {
    type: "collapse",
    name: "Upload-Data",
    icon: <IconWorldUpload />,
    key: "upload",
    route: "upload",
    component: <Inspections />,
  },
  // {
  //   type: "title",
  //   name: "Alerts",
  //   key: "Alerts",
  // },
  // sessionStorage.getItem("level") === "branch"
  //   ? {
  //       type: "collapse",
  //       name: "Compliances",
  //       // icon: <IconAlertOctagon />,
  //       // icon: <IconExclamationCircle />,
  //       icon: <IconAlertTriangle />,
  //       key: "Alerts",
  //       route: "Alerts",
  //       component: <Alerts />,
  //     }
  //   : {
  //       type: "collapse",
  //       name: "Compliances",
  //       icon: <IconAlertOctagon />,
  //       key: "Alerts",
  //       route: "Alerts",
  //       component: <Alerts_Authority />,
  //     },
  // {
  //   type: "title",
  //   name: "Compliance",
  //   key: "Compliance",
  // },
  // {
  //   type: "collapse",
  //   name: "High Level Compliances",
  //   icon: <IconListDetails />,
  //   key: "compliances",
  //   route: "/compliances",
  //   component: <NPA_Summary />,
  //   childrens: [
  //     {
  //       type: "collapse",
  //       name: `Account-Compliance`,
  //       key: `compliance/:id`,
  //       route: "compliance/:id",
  //       component: <AccDashboard />,
  //     },
  //   ],
  // },
];

export default routes;
