import React, { useEffect, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
  TextField,
  Button,
  Typography,
  Grid,
  Paper,
  Divider,
  Checkbox,
  FormControlLabel,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Loader from "components/Loader";
import { useLocation } from "react-router-dom";
import axios from "axios";
import styles from "./LoanReview.module.css";
import { toast } from "react-toastify";
import { IconDownload, IconEdit, IconTrash } from "@tabler/icons-react";

export default function LoanReview() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [formVisible, setFormVisible] = useState(true);
  const route = useLocation().pathname.split("/").slice(1);
  const accountNo = route[route.length - 1];
  const [loanReviewHistory, setLoanReviewHistory] = useState([]);
  // form data handle

  const defaultValues = {
    borrowerName: "",
    accountNumber: accountNo,
    constitution: "",
    natureOfBusiness: "",
    limitFacilitiesSince: "",
    creditFacilities: [
      {
        type: "",
        margin: "",
        amount: "",
        interestRate: "",
        primeSecurity: "",
        value: "",
        insurance: "",
        presentOs: "",
        overdue: false,
        lastReviewRenewal: "",
      },
    ],
    personalGuarantees: [{ name: "" }],
    collateralSecurities: [
      {
        propertyLocation: "",
        value: "",
      },
    ],
    otherBankCreditFacilities: [
      {
        bankName: "",
        branch: "",
        typeOfAdvance: "",
        amountOfLimit: "",
        outstanding: "",
        remarks: "",
      },
    ],
    sisterConcernCreditFacilities: [
      {
        bankName: "",
        branch: "",
        typeOfAdvance: "",
        limitSanction: "",
        outstanding: "",
        remarks: "",
      },
    ],
    marketStatus: "",
    operationsExperience: "",
    lastInspectionDate: "",
    inspectingOfficer: "",
    inspectionObservations: "",
    financialParameters: [
      {
        name: "OPENING STOCK",
        yearValues: [
          {
            year: "",
            value: "",
          },
        ],
      },
      {
        name: "NET SALES",
        yearValues: [
          {
            year: "",
            value: "",
          },
        ],
      },
      {
        name: "NET PURCHASES",
        yearValues: [
          {
            year: "",
            value: "",
          },
        ],
      },
      {
        name: "CLOSING STOCKS",
        yearValues: [
          {
            year: "",
            value: "",
          },
        ],
      },
      {
        name: "GROSS PROFIT",
        yearValues: [
          {
            year: "",
            value: "",
          },
        ],
      },
      {
        name: "DEPRECIATION",
        yearValues: [
          {
            year: "",
            value: "",
          },
        ],
      },
      {
        name: "NET PROFIT",
        yearValues: [
          {
            year: "",
            value: "",
          },
        ],
      },
      {
        name: "CAPITAL",
        yearValues: [
          {
            year: "",
            value: "",
          },
        ],
      },
      {
        name: "ALL SECURED TERM LOAN",
        yearValues: [
          {
            year: "",
            value: "",
          },
        ],
      },
      {
        name: "CASH CREDIT/ OD",
        yearValues: [
          {
            year: "",
            value: "",
          },
        ],
      },
      {
        name: "UNSECURED LOANS",
        yearValues: [
          {
            year: "",
            value: "",
          },
        ],
      },
      {
        name: "SUNDRY CREDITORS",
        yearValues: [
          {
            year: "",
            value: "",
          },
        ],
      },
      {
        name: "OTHER CURRENT LIABILITIES",
        yearValues: [
          {
            year: "",
            value: "",
          },
        ],
      },
      {
        name: "FIXED ASSETS",
        yearValues: [
          {
            year: "",
            value: "",
          },
        ],
      },
      {
        name: "INVESTMENTS",
        yearValues: [
          {
            year: "",
            value: "",
          },
        ],
      },
      {
        name: "SUNDRY DEBTORS",
        yearValues: [
          {
            year: "",
            value: "",
          },
        ],
      },
      {
        name: "CLOSING STOCKS",
        yearValues: [
          {
            year: "",
            value: "",
          },
        ],
      },
      {
        name: "CASH/BANK BALANCE",
        yearValues: [
          {
            year: "",
            value: "",
          },
        ],
      },
      {
        name: "OTHERS",
        yearValues: [
          {
            year: "",
            value: "",
          },
        ],
      },
      {
        name: "TOTAL INTEREST PAID AMOUNT DURING YEAR",
        yearValues: [
          {
            year: "",
            value: "",
          },
        ],
      },
      {
        name: "ACTUAL ALL EMI PAYABLE DURING YEAR FOR EXISTING TERM LOANS (Maximum 12 EMI)",
        yearValues: [
          {
            year: "",
            value: "",
          },
        ],
      },
      {
        name: "NET PROFIT / SALES RATIO",
        yearValues: [
          {
            year: "",
            value: "",
          },
        ],
      },
      {
        name: "CURRENT RATIO (CA / CL)",
        yearValues: [
          {
            year: "",
            value: "",
          },
        ],
      },
      {
        name: "DEBT EQUITY RATIO",
        yearValues: [
          {
            year: "",
            value: "",
          },
        ],
      },
      {
        name: "D.S.C.R.",
        yearValues: [
          {
            year: "",
            value: "",
          },
        ],
      },
    ],
    auditIrregularities: "",
    remarks: "",
  };
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues,
  });

  // Field Arrays
  const {
    fields: creditFacilityFields,
    append: appendCreditFacility,
    remove: removeCreditFacility,
  } = useFieldArray({
    control,
    name: "creditFacilities",
  });

  const {
    fields: personalGuaranteeFields,
    append: appendPersonalGuarantee,
    remove: removePersonalGuarantee,
  } = useFieldArray({
    control,
    name: "personalGuarantees",
  });

  const {
    fields: collateralSecuritiesFields,
    append: appendCollateralSecurity,
    remove: removeCollateralSecurity,
  } = useFieldArray({
    control,
    name: "collateralSecurities",
  });

  const {
    fields: otherBankCreditFields,
    append: appendOtherBankCredit,
    remove: removeOtherBankCredit,
  } = useFieldArray({
    control,
    name: "otherBankCreditFacilities",
  });

  const {
    fields: sisterConcernFields,
    append: appendSisterConcern,
    remove: removeSisterConcern,
  } = useFieldArray({
    control,
    name: "sisterConcernCreditFacilities",
  });

  const {
    fields: financialParameterFields,
    append: appendFinancialParameter,
    remove: removeFinancialParameter,
  } = useFieldArray({
    control,
    name: "financialParameters",
  });
  // Create an array of useFieldArray hooks for each financial parameter's yearValues
  const yearFieldsArrays = financialParameterFields.map((_, paramIndex) =>
    useFieldArray({
      control,
      name: `financialParameters[${paramIndex}].yearValues`,
    })
  );
  const onSubmit = async (data) => {
    const url = backendUrl + `/loan-review/create`;
    console.log(data);
    try {
      const response = await axios({
        method: "post",
        url: url,
        data: data,
      });
      toast.success(response?.data?.message || "Submitted");
      setLoanReviewHistory((prev) => [...prev, response?.data]);
      reset({ defaultValues });
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  // lead data form
  const {
    handleSubmit: handleSubmit2,
    control: control2,
    formState: { isSubmitting: isSubmitting2 },
  } = useForm({
    defaultValues: {
      accountNo: accountNo,
      leadNo: "",
      type: "",
    },
  });
  const getLeadData = async (data) => {
    try {
      const url =
        backendUrl +
        `/loan-review/fetchData?acctNo=${data.accountNo}&lead_no=${data.leadNo}&type=${data.type}`;
      const response = await axios.get(url);
      console.log(response);
      setFormVisible(true);
      reset(
        {
          ...defaultValues,
          borrowerName: response?.data?.customerDetails?.acctNm || "",
          personalGuarantees: response?.data?.guarantor_details || [{ name: "" }],
          natureOfBusiness: response?.data?.nature_of_business || "",
          limitFacilitiesSince: response?.data?.customerDetails?.opDate || "",
        },
        { keepDirty: true, keepTouched: true }
      );
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#edf6f9",
    },
    // hide last border
  }));

  // loan review history
  const getLoanHistory = async () => {
    try {
      const url = backendUrl + `/loan-review/get?acctNo=${accountNo}`;
      const res = await axios.get(url);
      setLoanReviewHistory(res.data || []);
    } catch (error) {
      console.log(error?.response?.data?.message || error?.message);
    }
  };
  const deleteRecord = async (id) => {
    const url = backendUrl + `/loan-review/delete/${id}`;
    try {
      const res = await axios.delete(url);
      const updatedHistory = loanReviewHistory.filter((item) => item.id !== id);
      setLoanReviewHistory(updatedHistory);
      toast.success(res?.data?.message || "Record Deleted");
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
  const editRecord = (data) => {
    reset(data);
  };
  const downloadExcel = (id) => {
    const url = backendUrl + `/loan-review/download/excel/${id}`;
    window.open(url, "_blank");
  };

  useEffect(() => {
    getLoanHistory();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isSubmitting ? (
        <Loader />
      ) : (
        <>
          <div className={styles.formContainer}>
            <Typography variant="h4" gutterBottom textAlign="center">
              Fetch Lead Data
            </Typography>
            <form onSubmit={handleSubmit2(getLeadData)}>
              <div className={styles.formGroup}>
                <Controller
                  name="accountNo"
                  control={control2}
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      label="Account Number"
                      fullWidth
                      disabled
                      error={!!error}
                      helperText={error?.message}
                      variant="outlined"
                    />
                  )}
                />
              </div>
              <div className={styles.formGroup}>
                <Controller
                  name="type"
                  control={control2}
                  defaultValue=""
                  rules={{ required: "Type is required" }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      id="type"
                      label="Type"
                      select
                      fullWidth
                      required
                      error={!!error}
                      helperText={error ? error.message : null}
                      SelectProps={{
                        native: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    >
                      <option value="">Select type...</option>
                      <option value="msme">MSME</option>
                      <option value="retail">Retail</option>
                    </TextField>
                  )}
                />
              </div>
              <div className={styles.formGroup}>
                <Controller
                  name="leadNo"
                  control={control2}
                  defaultValue=""
                  rules={{ required: "Lead Number is required" }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      label="Lead Number"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      variant="outlined"
                    />
                  )}
                />
              </div>
              <Button
                className="ActionButtonsColor"
                disabled={isSubmitting2}
                variant="contained"
                type="submit"
                fullWidth
              >
                {isSubmitting2 ? <CircularProgress size={24} /> : "Search"}
              </Button>
            </form>
          </div>
          {formVisible ? (
            <>
              <div className={styles.shadow} />
              <div style={{ margin: "20px" }}>
                <Typography variant="h5" gutterBottom textAlign="center">
                  Loan Review History
                </Typography>
                <div className={styles.historyContainer}>
                  <TableContainer
                    sx={{
                      maxHeight: "400px",
                      border: "1px solid lightgray",
                      boxShadow: 0,
                      overflow: "auto",
                    }}
                  >
                    <Table sx={{ minWidth: "600px" }}>
                      <TableHead sx={{ display: "contents", userSelect: "none" }}>
                        <TableRow
                          className="NewTableHeader"
                          sx={{
                            color: "white !important",
                            position: "sticky",
                            top: 0,
                            zIndex: 999,
                            cursor: "pointer",
                          }}
                        >
                          <TableCell className="NewTableHeaderFontColor" align="left">
                            Account No
                          </TableCell>
                          <TableCell className="NewTableHeaderFontColor" align="left">
                            Borrower Name
                          </TableCell>
                          <TableCell className="NewTableHeaderFontColor" align="left">
                            Constitution
                          </TableCell>
                          <TableCell className="NewTableHeaderFontColor" align="left">
                            Remarks
                          </TableCell>
                          <TableCell className="NewTableHeaderFontColor" align="left">
                            Created Time
                          </TableCell>
                          <TableCell className="NewTableHeaderFontColor" align="left">
                            Actions
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {loanReviewHistory?.length ? (
                          loanReviewHistory?.map((row) => (
                            <StyledTableRow key={row.id}>
                              <TableCell className="NewTableCells" align="left">
                                {row.accountNumber}
                              </TableCell>
                              <TableCell className="NewTableCells" align="left">
                                {row.borrowerName}
                              </TableCell>
                              <TableCell className="NewTableCells" align="left">
                                {row.constitution}
                              </TableCell>
                              <TableCell className="NewTableCells" align="left">
                                {row.remarks}
                              </TableCell>
                              <TableCell className="NewTableCells" align="left">
                                {row.createdTime}
                              </TableCell>
                              <TableCell className="NewTableCells" align="left">
                                <Button
                                  onClick={() => deleteRecord(row.id)}
                                  sx={{ color: "red" }}
                                  startIcon={<IconTrash />}
                                >
                                  Delete
                                </Button>
                                <Button onClick={() => editRecord(row)} startIcon={<IconEdit />}>
                                  Edit
                                </Button>
                                <Button
                                  onClick={() => downloadExcel(row.id)}
                                  startIcon={<IconDownload />}
                                >
                                  Download Excel
                                </Button>
                              </TableCell>
                            </StyledTableRow>
                          ))
                        ) : (
                          <StyledTableRow>
                            <TableCell className="NewTableCells" align="left" colSpan={6}>
                              No Data
                            </TableCell>
                          </StyledTableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <Typography variant="h5" gutterBottom textAlign="center">
                  Loan Review Form
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={3}>
                    {/* Borrower Information */}
                    <Grid item xs={12} mt={4}>
                      <Typography variant="h6">Borrower Information</Typography>
                      <Divider
                        sx={{ width: "100%", bgcolor: "black !important", height: "1.4px" }}
                        flexItem
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="borrowerName"
                        control={control}
                        rules={{ required: "Borrower Name is required" }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            label="Borrower Name"
                            fullWidth
                            required
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="accountNumber"
                        control={control}
                        defaultValue=""
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            label="Account Number"
                            fullWidth
                            disabled
                            error={!!error}
                            helperText={error?.message}
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="constitution"
                        control={control}
                        rules={{ required: "Constitution is required" }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            label="Constitution"
                            fullWidth
                            required
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="natureOfBusiness"
                        control={control}
                        rules={{ required: "Nature of Business is required" }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            label="Nature of Business"
                            fullWidth
                            required
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="limitFacilitiesSince"
                        control={control}
                        rules={{ required: "Limit Facilities Since is required" }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            label="Limit Facilities Since"
                            type="date"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            required
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </Grid>

                    {/* Credit Facilities */}
                    <Grid item xs={12} mt={4}>
                      <Typography variant="h6">Credit Facilities</Typography>
                      <Divider
                        sx={{ width: "100%", bgcolor: "black !important", height: "1.4px" }}
                        flexItem
                      />
                    </Grid>
                    {/* <Grid item xs={12}> */}
                    <Grid item xs={12}>
                      <TableContainer sx={{ border: "1px solid lightgray", boxShadow: 0 }}>
                        <Table>
                          <TableHead>
                            <TableRow className="NewTableHeader">
                              <TableCell className="NewTableHeaderFontColor">Type</TableCell>
                              <TableCell className="NewTableHeaderFontColor">Margin</TableCell>
                              <TableCell className="NewTableHeaderFontColor">Amount</TableCell>
                              <TableCell className="NewTableHeaderFontColor">
                                Interest Rate (%)
                              </TableCell>
                              <TableCell className="NewTableHeaderFontColor">
                                Prime Security
                              </TableCell>
                              <TableCell className="NewTableHeaderFontColor">Value</TableCell>
                              <TableCell className="NewTableHeaderFontColor">Insurance</TableCell>
                              <TableCell className="NewTableHeaderFontColor">Present OS</TableCell>
                              <TableCell className="NewTableHeaderFontColor">Overdue</TableCell>
                              <TableCell className="NewTableHeaderFontColor">
                                Last Review/Renewal
                              </TableCell>
                              <TableCell className="NewTableHeaderFontColor">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {creditFacilityFields.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell align="left" sx={{ minWidth: "200px" }}>
                                  <Controller
                                    name={`creditFacilities[${index}].type`}
                                    control={control}
                                    rules={{ required: "Type is required" }}
                                    render={({ field, fieldState: { error } }) => (
                                      <TextField
                                        {...field}
                                        //   fullWidth
                                        required
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                      />
                                    )}
                                  />
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: "200px" }}>
                                  <Controller
                                    name={`creditFacilities[${index}].margin`}
                                    control={control}
                                    render={({ field }) => <TextField {...field} type="number" />}
                                  />
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: "200px" }}>
                                  <Controller
                                    name={`creditFacilities[${index}].amount`}
                                    control={control}
                                    rules={{ required: "Amount is required" }}
                                    render={({ field, fieldState: { error } }) => (
                                      <TextField
                                        {...field}
                                        type="number"
                                        required
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                      />
                                    )}
                                  />
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: "200px" }}>
                                  <Controller
                                    name={`creditFacilities[${index}].interestRate`}
                                    control={control}
                                    render={({ field }) => <TextField {...field} type="number" />}
                                  />
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: "200px" }}>
                                  <Controller
                                    name={`creditFacilities[${index}].primeSecurity`}
                                    control={control}
                                    render={({ field }) => <TextField {...field} />}
                                  />
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: "200px" }}>
                                  <Controller
                                    name={`creditFacilities[${index}].value`}
                                    control={control}
                                    render={({ field }) => <TextField {...field} type="number" />}
                                  />
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: "200px" }}>
                                  <Controller
                                    name={`creditFacilities[${index}].insurance`}
                                    control={control}
                                    render={({ field }) => <TextField {...field} type="number" />}
                                  />
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: "200px" }}>
                                  <Controller
                                    name={`creditFacilities[${index}].presentOs`}
                                    control={control}
                                    render={({ field }) => <TextField {...field} type="number" />}
                                  />
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: "200px" }}>
                                  <Controller
                                    name={`creditFacilities[${index}].overdue`}
                                    control={control}
                                    render={({ field }) => (
                                      <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label=""
                                      />
                                    )}
                                  />
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: "200px" }}>
                                  <Controller
                                    name={`creditFacilities[${index}].lastReviewRenewal`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        type="date"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    )}
                                  />
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    minWidth: "200px",
                                    display: index == 0 ? "none" : "",
                                    borderTop: index == 1 ? "1px solid lightgray" : "",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => removeCreditFacility(index)}
                                    color="secondary"
                                  >
                                    <Remove />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell colSpan={12} sx={{ borderTop: "1px solid lightgray" }}>
                                <Button
                                  variant="contained"
                                  className="ActionButtonsColor"
                                  startIcon={<Add />}
                                  onClick={() =>
                                    appendCreditFacility({
                                      type: "",
                                      margin: "",
                                      amount: "",
                                      interestRate: "",
                                      primeSecurity: "",
                                      value: "",
                                      insurance: "",
                                      presentOs: "",
                                      overdue: false,
                                      lastReviewRenewal: "",
                                    })
                                  }
                                >
                                  Add Credit Facility
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>

                    {/* Personal Guarantees */}
                    <Grid item xs={12} mt={4}>
                      <Typography variant="h6">Personal Guarantees</Typography>
                      <Divider
                        sx={{ width: "100%", bgcolor: "black !important", height: "1.4px" }}
                        flexItem
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TableContainer sx={{ boxShadow: 0, border: "1px solid lightgray" }}>
                        <Table>
                          <TableHead>
                            <TableRow className="NewTableHeader">
                              <TableCell className="NewTableHeaderFontColor">
                                Guarantee Name
                              </TableCell>
                              <TableCell className="NewTableHeaderFontColor">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {personalGuaranteeFields.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell align="left" sx={{ minWidth: "200px" }}>
                                  <Controller
                                    name={`personalGuarantees[${index}].name`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        label={`Guarantee ${index + 1}`}
                                        fullWidth
                                      />
                                    )}
                                  />
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    display: index == 0 ? "none" : "",
                                    borderTop: index == 1 ? "1px solid lightgray" : "",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => removePersonalGuarantee(index)}
                                    color="secondary"
                                  >
                                    <Remove />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell colSpan={2} sx={{ borderTop: "1px solid lightgray" }}>
                                <Button
                                  variant="contained"
                                  className="ActionButtonsColor"
                                  startIcon={<Add />}
                                  onClick={() => appendPersonalGuarantee({ name: "" })}
                                >
                                  Add Personal Guarantee
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>

                    {/* Collateral Securities */}
                    <Grid item xs={12} mt={4}>
                      <Typography variant="h6">Collateral Securities</Typography>
                      <Divider
                        sx={{ width: "100%", bgcolor: "black !important", height: "1.4px" }}
                        flexItem
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TableContainer sx={{ boxShadow: 0, border: "1px solid lightgray" }}>
                        <Table>
                          <TableHead>
                            <TableRow className="NewTableHeader">
                              <TableCell className="NewTableHeaderFontColor">
                                Property Location
                              </TableCell>
                              <TableCell className="NewTableHeaderFontColor">Value</TableCell>
                              <TableCell className="NewTableHeaderFontColor">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {collateralSecuritiesFields.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <Controller
                                    name={`collateralSecurities[${index}].propertyLocation`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField {...field} label="Property Location" fullWidth />
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Controller
                                    name={`collateralSecurities[${index}].value`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField {...field} label="Value" type="number" fullWidth />
                                    )}
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    display: index == 0 ? "none" : "",
                                    borderTop: index == 1 ? "1px solid lightgray" : "",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => removeCollateralSecurity(index)}
                                    color="secondary"
                                  >
                                    <Remove />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell colSpan={3} sx={{ borderTop: "1px solid lightgray" }}>
                                <Button
                                  variant="contained"
                                  className="ActionButtonsColor"
                                  startIcon={<Add />}
                                  onClick={() =>
                                    appendCollateralSecurity({ propertyLocation: "", value: "" })
                                  }
                                >
                                  Add Collateral Security
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>

                    {/* Other Bank Credit Facilities */}
                    <Grid item xs={12} mt={4}>
                      <Typography variant="h6">Other Bank Credit Facilities</Typography>
                      <Divider
                        sx={{ width: "100%", bgcolor: "black !important", height: "1.4px" }}
                        flexItem
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TableContainer sx={{ boxShadow: 0, border: "1px solid lightgray" }}>
                        <Table>
                          <TableHead>
                            <TableRow className="NewTableHeader">
                              <TableCell className="NewTableHeaderFontColor">Bank Name</TableCell>
                              <TableCell className="NewTableHeaderFontColor">Branch</TableCell>
                              <TableCell className="NewTableHeaderFontColor">
                                Type of Advance
                              </TableCell>
                              <TableCell className="NewTableHeaderFontColor">
                                Amount of Limit
                              </TableCell>
                              <TableCell className="NewTableHeaderFontColor">Outstanding</TableCell>
                              <TableCell className="NewTableHeaderFontColor">Remarks</TableCell>
                              <TableCell className="NewTableHeaderFontColor">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {otherBankCreditFields.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <Controller
                                    name={`otherBankCreditFacilities[${index}].bankName`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField {...field} label="Bank Name" fullWidth />
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Controller
                                    name={`otherBankCreditFacilities[${index}].branch`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField {...field} label="Branch" fullWidth />
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Controller
                                    name={`otherBankCreditFacilities[${index}].typeOfAdvance`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField {...field} label="Type of Advance" fullWidth />
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Controller
                                    name={`otherBankCreditFacilities[${index}].amountOfLimit`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        label="Amount of Limit"
                                        type="number"
                                        fullWidth
                                      />
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Controller
                                    name={`otherBankCreditFacilities[${index}].outstanding`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        label="Outstanding"
                                        type="number"
                                        fullWidth
                                      />
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Controller
                                    name={`otherBankCreditFacilities[${index}].remarks`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField {...field} label="Remarks" fullWidth />
                                    )}
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    display: index == 0 ? "none" : "",
                                    borderTop: index == 1 ? "1px solid lightgray" : "",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => removeOtherBankCredit(index)}
                                    color="secondary"
                                  >
                                    <Remove />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell colSpan={7} sx={{ borderTop: "1px solid lightgray" }}>
                                <Button
                                  variant="contained"
                                  className="ActionButtonsColor"
                                  startIcon={<Add />}
                                  onClick={() =>
                                    appendOtherBankCredit({
                                      bankName: "",
                                      branch: "",
                                      typeOfAdvance: "",
                                      amountOfLimit: "",
                                      outstanding: "",
                                      remarks: "",
                                    })
                                  }
                                >
                                  Add Other Bank Credit Facility
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>

                    {/* Sister Concern Credit Facilities */}
                    <Grid item xs={12} mt={4}>
                      <Typography variant="h6">Sister Concern Credit Facilities</Typography>
                      <Divider
                        sx={{ width: "100%", bgcolor: "black !important", height: "1.4px" }}
                        flexItem
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TableContainer sx={{ boxShadow: 0, border: "1px solid lightgray" }}>
                        <Table>
                          <TableHead>
                            <TableRow className="NewTableHeader">
                              <TableCell className="NewTableHeaderFontColor">Bank Name</TableCell>
                              <TableCell className="NewTableHeaderFontColor">Branch</TableCell>
                              <TableCell className="NewTableHeaderFontColor">
                                Type of Advance
                              </TableCell>
                              <TableCell className="NewTableHeaderFontColor">
                                Limit Sanction
                              </TableCell>
                              <TableCell className="NewTableHeaderFontColor">Outstanding</TableCell>
                              <TableCell className="NewTableHeaderFontColor">Remarks</TableCell>
                              <TableCell className="NewTableHeaderFontColor">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {sisterConcernFields.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <Controller
                                    name={`sisterConcernCreditFacilities[${index}].bankName`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField {...field} label="Bank Name" fullWidth />
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Controller
                                    name={`sisterConcernCreditFacilities[${index}].branch`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField {...field} label="Branch" fullWidth />
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Controller
                                    name={`sisterConcernCreditFacilities[${index}].typeOfAdvance`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField {...field} label="Type of Advance" fullWidth />
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Controller
                                    name={`sisterConcernCreditFacilities[${index}].limitSanction`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        label="Limit Sanction"
                                        type="number"
                                        fullWidth
                                      />
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Controller
                                    name={`sisterConcernCreditFacilities[${index}].outstanding`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        label="Outstanding"
                                        type="number"
                                        fullWidth
                                      />
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Controller
                                    name={`sisterConcernCreditFacilities[${index}].remarks`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField {...field} label="Remarks" fullWidth />
                                    )}
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    display: index == 0 ? "none" : "",
                                    borderTop: index == 1 ? "1px solid lightgray" : "",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => removeSisterConcern(index)}
                                    color="secondary"
                                  >
                                    <Remove />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell colSpan={7} sx={{ borderTop: "1px solid lightgray" }}>
                                <Button
                                  variant="contained"
                                  className="ActionButtonsColor"
                                  startIcon={<Add />}
                                  onClick={() =>
                                    appendSisterConcern({
                                      bankName: "",
                                      branch: "",
                                      typeOfAdvance: "",
                                      limitSanction: "",
                                      outstanding: "",
                                      remarks: "",
                                    })
                                  }
                                >
                                  Add Sister Concern Credit Facility
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>

                    {/* Additional Information */}
                    <Grid item xs={12} mt={4}>
                      <Typography variant="h6">Additional Information</Typography>
                      <Divider
                        sx={{ width: "100%", bgcolor: "black !important", height: "1.4px" }}
                        flexItem
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="marketStatus"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Market Status"
                            multiline
                            rows={5}
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="operationsExperience"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Operations Experience"
                            multiline
                            rows={5}
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="lastInspectionDate"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Last Inspection Date"
                            type="date"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="inspectingOfficer"
                        control={control}
                        render={({ field }) => (
                          <TextField {...field} label="Inspecting Officer" fullWidth />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="inspectionObservations"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Inspection Observations"
                            fullWidth
                            multiline
                            rows={4}
                          />
                        )}
                      />
                    </Grid>

                    {/* Financial Parameters */}
                    <Grid item xs={12} mt={4}>
                      <Typography variant="h6">Financial Parameters</Typography>
                      <Divider
                        sx={{ width: "100%", bgcolor: "black !important", height: "1.4px" }}
                        flexItem
                      />
                    </Grid>
                    <>
                      {financialParameterFields.map((param, paramIndex) => {
                        // Get the corresponding useFieldArray instance
                        const {
                          fields: yearFields,
                          append: appendYear,
                          remove: removeYear,
                        } = yearFieldsArrays[paramIndex];

                        return (
                          <React.Fragment key={paramIndex}>
                            {/* <Grid item xs={12} sm={6} mt={2}>
                              
                            </Grid> */}
                            {/* Year Values */}
                            {/* <Grid item xs={12} sx={{ pt: "10px !important" }}>
                              <Typography variant="body1" textAlign="center">
                                Year Values
                              </Typography>
                            </Grid> */}
                            <Grid item xs={12}>
                              <TableContainer sx={{ boxShadow: 0, border: "1px solid lightgray" }}>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell colSpan={3}>
                                        <Controller
                                          name={`financialParameters[${paramIndex}].name`}
                                          control={control}
                                          render={({ field }) => (
                                            <TextField
                                              {...field}
                                              sx={{ maxWidth: 400 }}
                                              label="Parameter Name"
                                              fullWidth
                                            />
                                          )}
                                        />
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>Year</TableCell>
                                      <TableCell>Value</TableCell>
                                      <TableCell>Actions</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {yearFields.map((yearItem, yearIndex) => (
                                      <TableRow key={yearIndex}>
                                        <TableCell>
                                          <Controller
                                            name={`financialParameters[${paramIndex}].yearValues[${yearIndex}].year`}
                                            control={control}
                                            render={({ field }) => (
                                              <TextField
                                                {...field}
                                                label="Year"
                                                type="number"
                                                fullWidth
                                              />
                                            )}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <Controller
                                            name={`financialParameters[${paramIndex}].yearValues[${yearIndex}].value`}
                                            control={control}
                                            render={({ field }) => (
                                              <TextField
                                                {...field}
                                                label="Value"
                                                type="number"
                                                fullWidth
                                              />
                                            )}
                                          />
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            display: yearIndex === 0 ? "none" : "",
                                            borderTop: yearIndex === 1 ? "1px solid lightgray" : "",
                                          }}
                                        >
                                          <IconButton
                                            onClick={() => removeYear(yearIndex)}
                                            color="secondary"
                                          >
                                            <Remove />
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                    <TableRow>
                                      <TableCell
                                        colSpan={3}
                                        sx={{ borderTop: "1px solid lightgray" }}
                                      >
                                        <Button
                                          variant="contained"
                                          className="ActionButtonsColor"
                                          startIcon={<Add />}
                                          onClick={() => appendYear({ year: "", value: "" })}
                                        >
                                          Add Year Value
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </React.Fragment>
                        );
                      })}
                    </>
                    {/* Audit Irregularities and Remarks */}
                    <Grid item xs={12} mt={4}>
                      <Typography variant="h6">Additional Remarks</Typography>
                      <Divider
                        sx={{ width: "100%", bgcolor: "black !important", height: "1.4px" }}
                        flexItem
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="auditIrregularities"
                        control={control}
                        render={({ field }) => (
                          <TextField {...field} label="Audit Irregularities" fullWidth />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="remarks"
                        control={control}
                        render={({ field }) => (
                          <TextField {...field} label="Remarks" fullWidth multiline rows={4} />
                        )}
                      />
                    </Grid>

                    {/* Submit Button */}
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        className="ActionButtonsColor"
                        color="primary"
                        fullWidth
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </>
          ) : null}
        </>
      )}
    </DashboardLayout>
  );
}
