// @mui material components
import { forwardRef, useEffect, useState } from "react";
import React from "react";
import {
  Button,
  Stack,
  Typography,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
// components
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axios from "axios";
import { useLocation } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Loader from "components/Loader";
import { styled } from "@mui/material/styles";

// new interview modal transition
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Events() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const route = useLocation().pathname.split("/").slice(1);
  const id = route[route.length - 1];
  // new interview modal
  const [modalOpen, setModalOpen] = useState(false);
  const handleNewEvent = () => {
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
  };
  //   new interview details
  const [intialFormData, setInitialFormData] = useState({
    summary: "",
    eventType: "Legal Notice",
    eventImages: [],
  });
  const [formData, setFormData] = useState(intialFormData);

  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleImageUpload = async (e) => {
    let url = backendUrl + "/Events/uploadImages";
    const receivedFiles = e.target.files;
    try {
      const body = new FormData();
      for (let i = 0; i < receivedFiles.length; i++) {
        body.append("files", receivedFiles[i]);
      }
      const res = await axios.post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.status === 200) {
        toast.success("File uploaded");
      }
    } catch (error) {
      toast.error("Error upload file");
    }
    const fileNames = Array.from(receivedFiles).map((file) => file.name);

    setFormData((prevData) => ({
      ...prevData,
      eventImages: [...prevData.eventImages, ...fileNames],
    }));
  };

  //   process form submit
  const handleSubmit = async () => {
    const valid = validate();
    if (valid) {
      const url = backendUrl + "/Events/create";
      try {
        const res = await axios.post(url, { ...formData, accountNumber: id });
        if (res.status === 200) {
          toast.success("Form Submitted");
          setFormData(intialFormData);
          getEventsHistory();
        }
      } catch (error) {
        toast.error("Unable to save event");
      }
      handleClose();
    }
  };

  const getEventsHistory = async () => {
    const url = backendUrl + `/Events/history/${id}`;
    try {
      const res = await axios.get(url);
      if (res.status === 200) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getEventsHistory();
  }, []);
  // validation
  const [eventErrors, setEventErrors] = useState({});
  const validate = () => {
    const newErrors = {};
    let isValid = true;
    if (!formData.eventType.trim()) {
      newErrors.eventType = "Select Event Type";
      isValid = false;
    }
    if (!formData.summary.trim()) {
      newErrors.summary = "Provide Summary";
      isValid = false;
    }
    setEventErrors(newErrors);
    return isValid;
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#edf6f9",
    },
    // hide last border
  }));
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Loader />
      ) : (
        <MDBox sx={{ mt: 3 }}>
          <Typography sx={{ marginLeft: "3px", textAlign: "center" }} variant="h4">
            Events History
          </Typography>
          <TableContainer
            sx={{
              maxHeight: "calc(100vh - 220px)",
              boxShadow: 0,
              border: "1px solid lightgray",
              overflow: "auto",
            }}
          >
            <Table sx={{ minWidth: "600px" }}>
              <TableHead sx={{ width: "100%", display: "contents" }}>
                <TableRow
                  className="NewTableHeader"
                  sx={{
                    backgroundColor: "#49a3f1",
                    color: "white !important",
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                  }}
                >
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    A/C No
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Event Type
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Event Date
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Summary
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Report
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length ? (
                  data.map((item, index) => {
                    return (
                      <StyledTableRow key={index}>
                        <TableCell className="NewTableCells" align="left">
                          {item.accountNumber ? item.accountNumber : "---"}
                        </TableCell>
                        <TableCell className="NewTableCells" align="left">
                          {item.eventType ? item.eventType : "---"}
                        </TableCell>
                        <TableCell className="NewTableCells" align="left">
                          {item.createDateTime
                            ? moment(item.createDateTime).format("DD-MM-YYYY hh:mm")
                            : "---"}
                        </TableCell>
                        <TableCell
                          className="NewTableCells"
                          align="left"
                          sx={{ maxWidth: "200px", overflow: "hidden" }}
                        >
                          <div style={{ maxWidth: "220px", wordWrap: "break-word" }}>
                            {item.summary || "---"}
                          </div>
                        </TableCell>
                        <TableCell className="NewTableCells" align="left">
                          {item.report ? (
                            <a href={item.report} download>
                              <Button>Download</Button>
                            </a>
                          ) : (
                            "---"
                          )}
                        </TableCell>
                      </StyledTableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack alignItems="flex-end" my={1}>
            <Button
              className="ActionButtonsColor"
              variant="contained"
              onClick={handleNewEvent}
              sx={{ color: "white !important", background: "#49a3ffff" }}
            >
              Record New
            </Button>
          </Stack>
        </MDBox>
      )}
      <Dialog
        open={Boolean(modalOpen)}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Record new event"}</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            // minWidth: "450px",
          }}
        >
          <TextField
            select
            label="Event Type"
            fullWidth
            sx={{ height: "50px", mt: 1 }}
            value={formData.eventType}
            onChange={(e) => handleInputChange("eventType", e.target.value)}
            SelectProps={{
              native: true, // Use native select element
            }}
            required
            error={!!eventErrors.eventType}
            helperText={eventErrors.eventType}
          >
            <option value="Legal Notice">Legal Notice</option>
            <option value="Suit File">Suit File</option>
            <option value="Hearing">Hearing</option>
            <option value="Decree">Decree</option>
            <option value="Transfer To Auca">Transfer to AUCA</option>
            <option value="Transfer To Recall Asset">Transfer To Recall Asset</option>
            {/* Add more event types as needed */}
          </TextField>

          <TextField
            label="Summary"
            fullWidth
            multiline
            rows={4}
            value={formData.summary}
            onChange={(e) => handleInputChange("summary", e.target.value)}
            required
            error={!!eventErrors.summary}
            helperText={eventErrors.summary}
          />
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="interview-image-upload"
            type="file"
            onChange={handleImageUpload}
          />
          <label htmlFor="interview-image-upload">
            <Button
              className="ActionButtonsColor"
              variant="contained"
              component="span"
              sx={{ color: "white !important" }}
            >
              Upload File
            </Button>
          </label>
          {formData?.eventImages?.length > 0 && (
            <TableContainer sx={{ boxShadow: 0, border: "1px solid", mt: 1 }}>
              <Table>
                <TableRow>
                  <TableCell>Sr_No</TableCell>
                  <TableCell>Name</TableCell>
                </TableRow>

                <TableBody>
                  {formData?.eventImages?.map((image, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{image}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleSubmit}>Submit</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Events;
