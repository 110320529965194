import React from "react";
import ReactECharts from "echarts-for-react";
import { Card } from "@mui/material";

const StatusChart = (data) => {
  const { actionDueAccounts, noActionTakenAccounts, npaTurningAccounts } = data;
  console.log(actionDueAccounts);
  const option = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      top: "5%",
      left: "center",
    },
    textStyle: {
      fontWeight: 700,
      color: "black",
      fontSize: 16,
      fontFamily: '"Quicksand", "Roboto", "Helvetica", "Arial", sans-serif',
    },
    series: [
      {
        // name: "Access From",
        type: "pie",
        radius: ["40%", "70%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 5,
          borderColor: "#fff",
          borderWidth: 2,
        },
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          {
            value: noActionTakenAccounts ? noActionTakenAccounts : 0,
            name: "ACTION PENDING ACCOUNTS ( OVER 7 DAYS )",
          },
          {
            value: actionDueAccounts ? actionDueAccounts : 0,
            name: "ACTION DUE ACCOUNTS",
          },
          {
            value: npaTurningAccounts ? npaTurningAccounts : 0,
            name: "TURNING NPA ACCOUNTS ( IN 7 DAYS )",
          },
        ],
        color: ["#f2cc8f", "#81b29a", "#3d405b", "#e07a5f", "#eab69f"],
      },
    ],
  };

  return (
    <Card className="NewCard">
      <ReactECharts option={option} style={{ height: "470px", width: "100%" }} />
    </Card>
  );
};

export default StatusChart;
