// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Inspections_formContainer__27fkA {
    /* background-color: #f9f9f9; */
    padding: 20px;
    margin: 20px auto;
    border-radius: 10px;
    /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); */
    max-width: 600px;
}

.Inspections_formGroup__RVpeZ {
    margin-bottom: 20px;
}

.Inspections_label__HM4eM {
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
}

.Inspections_button__IiLO5 {
    background-color: #6200ea;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.Inspections_button__IiLO5:hover {
    background-color: #4500b5;
}

.Inspections_tableContainer__OJlt5 {
    margin-top: 40px;
}

.Inspections_table__0YoVO {
    width: 100%;
    border-collapse: collapse;
}

.Inspections_table__0YoVO th,
.Inspections_table__0YoVO td {
    border: 1px solid #ccc;
    padding: 12px;
    text-align: left;
}

.Inspections_table__0YoVO th {
    background-color: #f4f4f4;
}

.Inspections_table__0YoVO tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}`, "",{"version":3,"sources":["webpack://./src/layouts/Inspection_List/Inspections.module.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,iDAAiD;IACjD,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;;IAEI,sBAAsB;IACtB,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".formContainer {\n    /* background-color: #f9f9f9; */\n    padding: 20px;\n    margin: 20px auto;\n    border-radius: 10px;\n    /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); */\n    max-width: 600px;\n}\n\n.formGroup {\n    margin-bottom: 20px;\n}\n\n.label {\n    font-weight: bold;\n    display: block;\n    margin-bottom: 8px;\n}\n\n.button {\n    background-color: #6200ea;\n    color: #fff;\n    padding: 10px 20px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n}\n\n.button:hover {\n    background-color: #4500b5;\n}\n\n.tableContainer {\n    margin-top: 40px;\n}\n\n.table {\n    width: 100%;\n    border-collapse: collapse;\n}\n\n.table th,\n.table td {\n    border: 1px solid #ccc;\n    padding: 12px;\n    text-align: left;\n}\n\n.table th {\n    background-color: #f4f4f4;\n}\n\n.table tbody tr:nth-child(even) {\n    background-color: #f9f9f9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": `Inspections_formContainer__27fkA`,
	"formGroup": `Inspections_formGroup__RVpeZ`,
	"label": `Inspections_label__HM4eM`,
	"button": `Inspections_button__IiLO5`,
	"tableContainer": `Inspections_tableContainer__OJlt5`,
	"table": `Inspections_table__0YoVO`
};
export default ___CSS_LOADER_EXPORT___;
