import React, { forwardRef, useEffect, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Dialog,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  DialogTitle,
  DialogContent,
  Stack,
  TableBody,
  Slide,
} from "@mui/material";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Loader from "components/Loader";
import moment from "moment";
import { styled } from "@mui/material/styles";

const points = [
  "Default in payment to the banks/sundry debtors and other statutory bodies.",
  "Under insured or over insured inventory",
  "Invoices devoid of TAN and other details",
  "Dispute on title of the collateral securities",
  // "Funds coming from other banks to liquidate the outstanding loan amount.",
  "Request received from the borrower to postpone the inspection of the godown for flimsy reasons",
  // "Financing the unit far away from the branch",
  "Frequent invocation of BGs and development of LCs issued by other banks.",
  // "Funding of the interest by sanctioning additional facilities",
  "Same collateral charged to a number of lenders.",
  "Concealment of certain vital documents like master agreement, insurance coverage.",
  // "Large number of transactions with inter-connected companies and large outstanding from such companies.",
  "Significant movements in inventory, disproportionately higher than the growth in turnover.",
  "Significant movements in receivables, disproportionately higher than the growth in turnover and/or increase in ageing of the receivables.",
  "Disproportionate increase in other current assets",
  "Significant increase in working capital borrowings as percentage of turnover.",
  "Critical issues highlighted in the stock audit report.",
  "Increase in Fixed Assets, without corresponding increase in turnover (when project is implemented).",
  "Increase in borrowings, despite huge cash and cash equivalents in the borrower's balance sheet.",
  "Substantial related party transactions.",
  "Poor disclosure of materially adverse information and no qualification by the statutory auditors.",
  "Frequent change in accounting period and/or accounting policies.",
  "Frequent request for general purpose loans.",
  "Movement of an account from one bank to another.",
  "Frequent ad hoc sanctions.",
  // "Non-routing of sales proceeds through bank.",
  "LCs issued for local trade/ related party transactions",
  // "High value RTGS payment to unrelated parties.",
  // "Heavy cash withdrawal in loan accounts.",
  "Non submission of original bills.",
];
const Compliance = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const route = useLocation().pathname.split("/").slice(1);
  const accountNumber = route[route.length - 1];
  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  // form handling
  const { control, handleSubmit, watch, setValue, reset } = useForm({
    defaultValues: {
      points: points.map((point, index) => ({
        pointNo: index + 1,
        point,
        decision: "no",
        description: "",
        documentNames: [],
      })),
    },
  });
  const { fields } = useFieldArray({
    control,
    name: "points",
  });
  const [uploadedFiles, setUploadedFiles] = useState(points.map(() => []));
  const handleFileChange = async (index, event) => {
    try {
      const url = `${backendUrl}/AlertOffline/${accountNumber}/${index + 1}/upload`;
      console.log("URL:", url);

      const files = Array.from(event.target.files);
      console.log("Files:", files);

      // uploaded files update
      const updatedUploadedFiles = [...uploadedFiles];
      if (!updatedUploadedFiles[index]) {
        updatedUploadedFiles[index] = [];
      }
      updatedUploadedFiles[index] = [...updatedUploadedFiles[index], ...files];
      setUploadedFiles(updatedUploadedFiles);

      // network call
      const formData = new FormData();
      files.forEach((file) => formData.append("files", file));

      try {
        const res = await axios({
          method: "post",
          url: url,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (res.status === 200) {
          toast.success(
            res.data.message || `Documents uploaded successfully for point ${index + 1}`
          );
          // Update form field with file names
          const updatedFormData = watch("points");
          updatedFormData[index].documentNames = updatedUploadedFiles[index].map(
            (file) => file.name
          );
          setValue("points", updatedFormData);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || error.message);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };
  const [modalOpen, setModalOpen] = useState(false);
  const handleNewAlert = () => {
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
  };
  const onSubmit = async (data) => {
    const url = backendUrl + `/AlertOffline/${accountNumber}/create`;
    const filteredPoints = data?.points?.filter((item) => item.decision === "yes");
    try {
      const res = await axios({
        url: url,
        method: "post",
        data: filteredPoints,
      });
      if (res.status === 200) {
        toast.success(res.data.message || "Form submitted succsessfully");
        // reset form data
        getComplianceHistory();
        reset({
          points: points.map((point, index) => ({
            pointNo: index + 1,
            point,
            decision: "no",
            description: "",
            documentNames: [],
          })),
        });

        // reset uplaoded files
        setUploadedFiles(points.map(() => []));
        // close dialog
        setModalOpen(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
    }
  };

  const [loading, setLoading] = useState(true);
  const [complianceHistory, setComplianceHistory] = useState([]);
  const getComplianceHistory = async () => {
    try {
      const url = backendUrl + "/AlertOffline/get/" + accountNumber;
      const res = await axios.get(url);
      setComplianceHistory(res?.data);
    } catch (error) {
      console.log(error?.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getComplianceHistory();
  }, []);
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#edf6f9",
    },
    // hide last border
  }));
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Loader />
      ) : (
        <MDBox sx={{ mt: 3 }}>
          <Typography sx={{ marginLeft: "3px", textAlign: "center" }} variant="h4">
            Offline Alerts History
          </Typography>
          <TableContainer
            sx={{
              maxHeight: "calc(100vh - 220px)",
              boxShadow: 0,
              border: "1px solid lightgray",
              overflow: "auto",
            }}
          >
            <Table sx={{ minWidth: "600px" }}>
              <TableHead sx={{ width: "100%", display: "contents" }}>
                <TableRow
                  className="NewTableHeader"
                  sx={{
                    backgroundColor: "#49a3f1",
                    color: "white !important",
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                  }}
                >
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Account No
                  </TableCell>

                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Alert Description
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Description
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {complianceHistory && complianceHistory.length ? (
                  complianceHistory.map((row) => (
                    <StyledTableRow key={row?.id}>
                      <TableCell className="NewTableCells" align="left">
                        {row?.accountNumber ? row?.accountNumber : "---"}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row?.point ? row?.point : "---"}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row?.description ? row?.description : "---"}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row?.createdTime
                          ? moment(row?.createdTime).format("DD-MM-YYYY / hh:mm")
                          : "---"}
                      </TableCell>
                      {/* <TableCell align="left">
                        {row.documentName && row.documentName.length
                          ? row.documentName.map((doc, index) => (
                              <a href={`/${doc}`} download key={index}>
                                <Button>Download {doc}</Button>
                              </a>
                            ))
                          : "---"}
                      </TableCell> */}
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack alignItems="flex-end" my={1}>
            <Button
              className="ActionButtonsColor"
              variant="contained"
              onClick={handleNewAlert}
              sx={{ color: "white !important", background: "#49a3ffff" }}
            >
              Record New
            </Button>
          </Stack>
        </MDBox>
      )}
      <Dialog
        open={modalOpen}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        aria-describedby="alert-dialog-slide-description111"
      >
        <DialogTitle>{"RECORD NEW ALERT"}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            {fields.map((item, index) => (
              <Box key={item.id} mb={3}>
                <Typography variant="h6">{`${index + 1}. ${points[index]}`}</Typography>
                <Controller
                  name={`points.${index}.decision`}
                  control={control}
                  render={({ field }) => (
                    <FormControl component="fieldset">
                      <RadioGroup row {...field}>
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
                {watch(`points.${index}.decision`) === "yes" && (
                  <>
                    <Controller
                      name={`points.${index}.description`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Description"
                          fullWidth
                          multiline
                          rows={4}
                          variant="outlined"
                          sx={{ mb: 2 }}
                        />
                      )}
                    />
                    <Button
                      variant="contained"
                      component="label"
                      sx={{ color: "white !important" }}
                    >
                      Upload Documents
                      <input
                        type="file"
                        hidden
                        multiple
                        onChange={(e) => handleFileChange(index, e)}
                      />
                    </Button>
                    {uploadedFiles[index] && uploadedFiles[index].length > 0 && (
                      <Typography variant="body1" color="textSecondary" sx={{ mt: 1 }}>
                        <b>Uploaded</b>: {uploadedFiles[index].map((file) => file.name).join(", ")}
                      </Typography>
                    )}
                  </>
                )}
              </Box>
            ))}
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ color: "white !important", position: "sticky", bottom: "0px" }}
              fullWidth
            >
              Submit
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
};

export default Compliance;
