import { useState, useEffect, lazy, Suspense } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
// import themeRTL from "assets/theme/theme-rtl"

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
// import themeDarkRTL from "assets/theme-dark/theme-rtl"

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import Loader from "components/Loader";
import Events from "layouts/Actions/Events";
import Alerts from "layouts/Alerts/Alerts";
import Alerts_Authority from "layouts/Alerts/Alerts_Authority";
import Compliance from "layouts/Actions/Compliance";

const SignIn = lazy(() => import("layouts/authentication/sign-in"));
const Dashboard = lazy(() => import("layouts/dashboard"));
const STD_Summary = lazy(() => import("layouts/STD_Accounts/Summary"));
const STD_Dashboard = lazy(() => import("layouts/STD_Accounts/AccDashboard"));
const SMA_Summary = lazy(() => import("layouts/SMA_Accounts/Summary"));
const SMA_Dashboard = lazy(() => import("layouts/SMA_Accounts/AccDashboard"));
const NPA_Summary = lazy(() => import("layouts/NPA_Accounts/Summary"));
const NPA_Dashboard = lazy(() => import("layouts/NPA_Accounts/AccDashboard"));
const Inspection = lazy(() => import("layouts/Actions/Inspection"));
const Interview = lazy(() => import("layouts/Actions/Interview"));
const Notice = lazy(() => import("layouts/Actions/Notice"));

// css
import "./App.css";
import Register_Case from "layouts/Case_Management/Register_Case";
import Inspections from "layouts/Inspection_List";
import Upload from "layouts/Upload";
import LoanReview from "layouts/Actions/LoanReview";
import LoanReviewAccounts from "layouts/Loan_review_accounts";
import Notices from "layouts/Notice_List";
export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,

    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const loggedin = sessionStorage.getItem("email");
  const [loading, setLoading] = useState(false);
  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && !!loggedin && (
        <>
          <Sidenav
            color={sidenavColor}
            // brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="S.S.B Digital"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {/* {configsButton} */}
        </>
      )}
      <Routes>
        {loggedin ? (
          <>
            <Route
              path="/"
              index
              element={
                <Suspense fallback={<Loader />}>
                  <Dashboard />
                </Suspense>
              }
            />
            <Route
              path="/inspections"
              index
              element={
                <Suspense fallback={<Loader />}>
                  <Inspections />
                </Suspense>
              }
            />
            <Route
              path="/upload"
              index
              element={
                <Suspense fallback={<Loader />}>
                  <Upload />
                </Suspense>
              }
            />
            <Route
              path="/notices"
              index
              element={
                <Suspense fallback={<Loader />}>
                  <Notices />
                </Suspense>
              }
            />
            <Route path="/loan-review" element={<Outlet />}>
              <Route
                index
                element={
                  <Suspense fallback={<Loader />}>
                    <LoanReviewAccounts />
                  </Suspense>
                }
              />
              <Route
                path="account/:id"
                index
                element={
                  <Suspense fallback={<Loader />}>
                    <LoanReview />
                  </Suspense>
                }
              />
            </Route>
            <Route path="/standard/summary" element={<Outlet />}>
              <Route
                index
                element={
                  <Suspense fallback={<Loader />}>
                    <STD_Summary />
                  </Suspense>
                }
              />
              <Route
                path="cases/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <Register_Case />
                  </Suspense>
                }
              />
              <Route
                path="dashboard/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <STD_Dashboard />
                  </Suspense>
                }
              />
              <Route
                path="inspection/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <Inspection />
                  </Suspense>
                }
              />
              <Route
                path="interview/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <Interview />
                  </Suspense>
                }
              />
              <Route
                path="notice/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <Notice />
                  </Suspense>
                }
              />
              <Route
                path="compliance/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <Compliance />
                  </Suspense>
                }
              />
            </Route>
            <Route path="/sma/summary" element={<Outlet />}>
              <Route
                index
                element={
                  <Suspense fallback={<Loader />}>
                    <SMA_Summary />
                  </Suspense>
                }
              />
              <Route
                path="cases/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <Register_Case />
                  </Suspense>
                }
              />
              <Route
                path="dashboard/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <SMA_Dashboard />
                  </Suspense>
                }
              />
              <Route
                path="inspection/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <Inspection />
                  </Suspense>
                }
              />
              <Route
                path="interview/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <Interview />
                  </Suspense>
                }
              />
              <Route
                path="notice/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <Notice />
                  </Suspense>
                }
              />
              <Route
                path="compliance/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <Compliance />
                  </Suspense>
                }
              />
            </Route>
            <Route path="/npa/summary" element={<Outlet />}>
              <Route
                index
                element={
                  <Suspense fallback={<Loader />}>
                    <NPA_Summary />
                  </Suspense>
                }
              />
              <Route
                path="dashboard/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <NPA_Dashboard />
                  </Suspense>
                }
              />
              <Route
                path="inspection/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <Inspection />
                  </Suspense>
                }
              />
              <Route
                path="interview/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <Interview />
                  </Suspense>
                }
              />
              <Route
                path="notice/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <Notice />
                  </Suspense>
                }
              />
              <Route
                path="events/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <Events />
                  </Suspense>
                }
              />
              <Route
                path="compliance/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <Compliance />
                  </Suspense>
                }
              />
              <Route
                path="cases/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <Register_Case />
                  </Suspense>
                }
              />
              <Route
                path="compliance/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <Compliance />
                  </Suspense>
                }
              />
            </Route>
            {sessionStorage.getItem("level") === "branch" ? (
              <Route path="/Alerts" element={<Outlet />}>
                <Route
                  index
                  element={
                    <Suspense fallback={<Loader />}>
                      <Alerts />
                    </Suspense>
                  }
                />
              </Route>
            ) : (
              <Route path="/Alerts" element={<Outlet />}>
                <Route
                  index
                  element={
                    <Suspense fallback={<Loader />}>
                      <Alerts_Authority />
                    </Suspense>
                  }
                />
              </Route>
            )}
          </>
        ) : (
          <Route path="/" index element={<SignIn />} />
        )}

        {/* <Route path="/compliances" element={<Outlet />}>
          <Route
            index
            element={
              <Suspense fallback={<Loader />}>
                <NPA_Summary />
              </Suspense>
            }
          />
        </Route> */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ThemeProvider>
  );
}
