import React, { useEffect, useState } from "react";
import {
  Button,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Paper,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axios from "axios";
import Loader from "components/Loader";
import MDBox from "components/MDBox";
import { useNavigate } from "react-router-dom";
import { IconCircleCheck, IconDownload, IconSquareRoundedX, IconUpload } from "@tabler/icons-react";
import { toast } from "react-toastify";
import moment from "moment";
import { styled } from "@mui/material/styles";

function Alerts_Authority() {
  const navigate = useNavigate();
  const [resolveDialogOpen, setResolveDialogOpen] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const itemsPerPage = 20;

  const url = `${backendUrl}/AlertResolution/all`;
  const getData = async () => {
    try {
      const res = await axios.get(url);
      if (res.status === 200) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (currentPage !== 1) setCurrentPage(1);
  }, [searchValue]);

  const renderSortArrow = (column) => {
    if (sortColumn === column) {
      return sortDirection === "asc" ? <span>&#9650;</span> : <span>&#9660;</span>;
    }
    return null;
  };

  const handleSort = (column) => {
    setCurrentPage(1);
    if (column === sortColumn) {
      setSortDirection((prevSortDirection) => (prevSortDirection === "asc" ? "desc" : "asc"));
    } else {
      setSortColumn(column);
      setSortDirection("desc");
    }
  };

  const sortedData = [...data].sort((a, b) => {
    let aValue = a[sortColumn];
    let bValue = b[sortColumn];

    if (sortColumn === "acctNo") {
      aValue = parseInt(aValue, 10);
      bValue = parseInt(bValue, 10);
    }

    if (sortDirection === "asc") {
      if (typeof aValue === "number" && typeof bValue === "number") {
        return aValue - bValue;
      }
      return aValue?.localeCompare(bValue);
    } else {
      if (typeof aValue === "number" && typeof bValue === "number") {
        return bValue - aValue;
      }
      return bValue?.localeCompare(aValue);
    }
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const filteredData = sortedData.filter(
    (item) =>
      item?.acctNo?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      item?.description?.toLowerCase().includes(searchValue?.toLowerCase())
  );
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = filteredData.length > 0 ? Math.ceil(filteredData.length / itemsPerPage) : 1;

  const nextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  // actions
  const Accept = async (id) => {
    try {
      const url = backendUrl + `/AlertResolution/accept?resolutionId=${id}`;
      const res = await axios.post(url);
      if (res.status === 200) {
        toast.success(res.data);
        setData((prev) =>
          prev.map((item) => {
            return item.resoluationId === id ? { ...item, status: "Accepted" } : item;
          })
        );
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
    }
  };
  const Reject = async (id) => {
    try {
      const url = backendUrl + `/AlertResolution/reject?resolutionId=${id}`;
      const res = await axios.post(url);
      if (res.status === 200) {
        toast.success(res.data);
        const updatedDada = data?.filter((item) => item.resoluationId !== id);
        setData(updatedDada);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
    }
  };
  const Download = async (id) => {
    const url = `${backendUrl}/AlertResolution/${id}/download`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;

      // Set the download attribute to force download
      link.setAttribute("download", "");

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up: remove the link from the DOM
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error.message);
      // Handle error as needed
    }
  };

  const level_authority = sessionStorage.getItem("level") === "branch" ? false : true;

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#edf6f9",
    },
    // hide last border

  }));

  return (
    <DashboardLayout>
      <DashboardNavbar setSearchValue={setSearchValue} loading={loading} show={true} />
      {loading ? (
        <Loader />
      ) : (
        <MDBox sx={{ mt: 3 }}>
          <TableContainer
            sx={{
              maxHeight: "calc(100vh - 190px)",
              boxShadow: 0,
              border: "1px solid lightgray",
              overflow: "auto",
            }}
          >
            <Table sx={{ minWidth: "600px" }}>
              <TableHead sx={{ display: "contents", userSelect: "none" }}>
                <TableRow
                  className="NewTableHeader"
                  sx={{
                    backgroundColor: "#49a3f1",
                    color: "white !important",
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                    cursor: "pointer",
                  }}
                >
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Account No {renderSortArrow("acctNo")}
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Branch
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    align="left"
                    onClick={() => handleSort("date")}
                  >
                    Date {renderSortArrow("date")}
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    align="left"
                    onClick={() => handleSort("description")}
                  >
                    Description {renderSortArrow("description")}
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    align="left"
                    onClick={() => handleSort("segement")}
                  >
                    Segment {renderSortArrow("segement")}
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    align="left"
                    onClick={() => handleSort("segement")}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    align="left"
                    onClick={() => setResolveDialogOpen(true)}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {currentData.length ? (
                  currentData.map((row, index) => (
                    <StyledTableRow key={index + 1}>
                      <TableCell className="NewTableCells" align="left">
                        {row.acctNumber || "---"}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        Ahmedabad {index + 1}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {moment(row.createdAt).format("DD-MM-YYYY") || "---"}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row.description || "---"}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row.segement || "---"}
                      </TableCell>
                      <TableCell
                        className="NewTableCells"
                        align="left"
                        sx={{ color: row.status === "Accepted" ? "green" : "orange" }}
                      >
                        {row.status || "---"}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row.status === "Pending" ? (
                          <>
                            <Button
                              size="small"
                              // color="success"
                              startIcon={<IconCircleCheck />}
                              sx={{ color: "green" }}
                              onClick={() => Accept(row.resoluationId)}
                            >
                              Accept
                            </Button>
                            <Button
                              size="small"
                              // color="error"
                              sx={{ color: "red" }}
                              startIcon={<IconSquareRoundedX />}
                              onClick={() => Reject(row.resoluationId)}
                            >
                              Reject
                            </Button>
                            <Button
                              size="small"
                              startIcon={<IconDownload />}
                              onClick={() => Download(row.resoluationId)}
                            >
                              Documents
                            </Button>
                          </>
                        ) : (
                          <>{<Typography variant="subtitle2">No Action Pending</Typography>}</>
                        )}
                      </TableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>No Data</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack direction="row" spacing={2} justifyContent="flex-end" m={1}>
            <Button onClick={prevPage} disabled={currentPage === 1 || data.length === 0}>
              Previous
            </Button>
            <Typography>
              {currentPage} / {totalPages}
            </Typography>
            <Button onClick={nextPage} disabled={currentPage === totalPages || data.length === 0}>
              Next
            </Button>
          </Stack>
          {/* <Dialog open={open} onClose={handleClose} sx={{ backdropFilter: "blur(4px)" }}>
            <DialogTitle>{resolveAlert.description}</DialogTitle>
            <DialogContent>
              <form onSubmit={handleResolve}>
                <TextField
                  required
                  multiline
                  rows={3}
                  fullWidth
                  disabled={uploadLoading || resolveLoading}
                  placeholder="Please Elaborate"
                ></TextField>
                <Button
                  variant="outlined"
                  component="label"
                  startIcon={uploadLoading ? null : <IconUpload size={20} />}
                  sx={{ color: "blue !important", mt: 1 }}
                  fullWidth
                >
                  {uploadLoading ? <CircularProgress size={24} /> : "Upload Documents"}
                  <input
                    disabled={resolveLoading || uploadLoading}
                    type="file"
                    hidden
                    multiple
                    onChange={handleFileChange}
                  />
                </Button>
                <TableContainer
                  component={Paper}
                  sx={{ boxShadow: 0, border: "1px solid lightgray", mt: 1, maxHeight: "203px" }}
                >
                  <Table>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 700 }}>Number</TableCell>
                      <TableCell sx={{ fontWeight: 700 }}>Document Name</TableCell>
                    </TableRow>

                    <TableBody>
                      {resolveAlert.documentName && resolveAlert.documentName.length > 0 ? (
                        resolveAlert.documentName.map((document, index) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{document}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            No documents available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Button
                  variant="contained"
                  sx={{ color: "white !important", mt: 1 }}
                  type="submit"
                  fullWidth
                  disabled={resolveLoading || uploadLoading}
                >
                  {resolveLoading ? <CircularProgress color="info" size={24} /> : "Submit"}
                </Button>
              </form>
            </DialogContent>
          </Dialog> */}
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default Alerts_Authority;
