import React, { useEffect, useState } from "react";
import {
  Button,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axios from "axios";
import Loader from "components/Loader";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { IconEye } from "@tabler/icons-react";

function Summary() {
  const navigate = useNavigate();
  const branch_code = sessionStorage.getItem("branch_code");
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const itemsPerPage = 20;

  const handleView = (id) => {
    navigate(`/sma/summary/dashboard/${id}`);
  };

  const url = process.env.REACT_APP_BACKEND_URL + `/account/SMA_Accounts?BranchCode=${branch_code}`;
  const getData = async () => {
    try {
      const res = await axios.get(url);
      if (res.status === 200) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchValue]);

  // sorting
  const renderSortArrow = (column) => {
    if (sortColumn === column) {
      return sortDirection === "asc" ? <span>&#9650;</span> : <span>&#9660;</span>;
    }
    return null;
  };

  const handleSort = (column) => {
    setCurrentPage(1);
    if (column === sortColumn) {
      // Toggle sort direction if clicking on the same column
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // Set sort column and reset sort direction
      setSortColumn(column);
      setSortDirection("asc");
    }
  };
  const sortedData = [...data].map((item) => ({
    ...item,
    custName: item.custName.replace(/[^a-zA-Z ]/g, "").trim(),
    add4: item.add4.replace(/[^a-zA-Z ]/g, "").trim(),
  }));
  if (sortColumn) {
    sortedData.sort((a, b) => {
      const aValue = sortColumn === "outstand" ? parseFloat(a[sortColumn]) : a[sortColumn];
      const bValue = sortColumn === "outstand" ? parseFloat(b[sortColumn]) : b[sortColumn];
      if (sortDirection === "asc") {
        return sortColumn === "outstand" ? aValue - bValue : aValue.localeCompare(bValue);
      } else {
        return sortColumn === "outstand" ? bValue - aValue : bValue.localeCompare(aValue);
      }
    });
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Filter data based on the search value
  const filteredData = sortedData.filter(
    (item) =>
      item?.acctNo?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      item?.custName.toLowerCase().includes(searchValue?.toLowerCase())
  );

  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = filteredData.length > 0 ? Math.ceil(filteredData.length / itemsPerPage) : 1;

  const nextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#edf6f9",
    },
    // hide last border
  }));
  return (
    <DashboardLayout>
      <DashboardNavbar setSearchValue={setSearchValue} loading={loading} show={true} />
      {loading ? (
        <Loader />
      ) : (
        <MDBox sx={{ mt: 3 }}>
          <TableContainer
            sx={{
              maxHeight: "calc(100vh - 190px)",
              boxShadow: 0,
              border: "1px solid lightgray",
              overflow: "auto",
            }}
          >
            <Table sx={{ minWidth: "600px" }}>
              <TableHead sx={{ display: "contents", userSelect: "none" }}>
                <TableRow
                  className="NewTableHeader"
                  sx={{
                    backgroundColor: "#49a3f1",
                    color: "white !important",
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                    cursor: "pointer",
                  }}
                >
                  <TableCell
                    className="NewTableHeaderFontColor"
                    align="left"
                    onClick={() => handleSort("acctNo")}
                  >
                    Account No {sortColumn === "acctNo" && renderSortArrow("acctNo")}
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    align="left"
                    onClick={() => handleSort("custName")}
                  >
                    Account Holder {sortColumn === "custName" && renderSortArrow("custName")}
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    align="left"
                    onClick={() => handleSort("telNo")}
                  >
                    Contact {sortColumn === "telNo" && renderSortArrow("telNo")}
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    align="left"
                    onClick={() => handleSort("outstand")}
                  >
                    Outstand {sortColumn === "outstand" && renderSortArrow("outstand")}
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    align="left"
                    onClick={() => handleSort("add4")}
                  >
                    Place {sortColumn === "add4" && renderSortArrow("add4")}
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    View
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentData.length ? (
                  currentData.map((row) => (
                    <StyledTableRow key={row.acctNo}>
                      <TableCell className="NewTableCells" align="left">
                        {row.acctNo}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row.custName}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row.telNo}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row.outstand}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row.add4}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        <Button onClick={() => handleView(row.acctNo)}>
                          <IconEye style={{ marginRight: "3px" }} />
                          View
                        </Button>
                      </TableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} sx={{ border: 0 }}>
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack direction="row" spacing={2} justifyContent="flex-end" m={1}>
            <Button onClick={prevPage} disabled={currentPage === 1}>
              Previous
            </Button>
            <Typography>
              {currentPage} / {totalPages}
            </Typography>
            <Button onClick={nextPage} disabled={currentPage === totalPages}>
              Next
            </Button>
          </Stack>
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default Summary;
