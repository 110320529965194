// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoanReview_formContainer__06Gx4 {
    /* background-color: #f9f9f9; */
    padding: 20px;
    margin: 20px auto;
    border-radius: 10px;
    /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); */
    max-width: 600px;
}

.LoanReview_formGroup__Qn-ri {
    margin-bottom: 20px;
}

.LoanReview_historyContainer__ZI1au {
    padding: 20px;
}

.LoanReview_shadow__Y6\\+mF {
    height: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}`, "",{"version":3,"sources":["webpack://./src/layouts/Actions/LoanReview.module.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,iDAAiD;IACjD,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,qFAAqF;AACzF","sourcesContent":[".formContainer {\n    /* background-color: #f9f9f9; */\n    padding: 20px;\n    margin: 20px auto;\n    border-radius: 10px;\n    /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); */\n    max-width: 600px;\n}\n\n.formGroup {\n    margin-bottom: 20px;\n}\n\n.historyContainer {\n    padding: 20px;\n}\n\n.shadow {\n    height: 5px;\n    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": `LoanReview_formContainer__06Gx4`,
	"formGroup": `LoanReview_formGroup__Qn-ri`,
	"historyContainer": `LoanReview_historyContainer__ZI1au`,
	"shadow": `LoanReview_shadow__Y6+mF`
};
export default ___CSS_LOADER_EXPORT___;
